<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="Old Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="currentPassword"
                    :type="typeCurrentPassword"
                    name="old-password"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPassword"
                    :type="typeNewPassword"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-confirm-new-password"
              label="Confirm New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-confirm-new-password"
                    v-model="confirmPassword"
                    :type="typeConfirmPassword"
                    name="confirm-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-1 mr-1"
              :disabled="loading"
              @click="handleChangePassword"
            >
              <Spinner v-if="loading" />
              <template v-else>
                Save changes
              </template>
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Spinner from '@core/components/Spinner'
import { mapState, mapActions } from 'vuex'
import { required, min, confirmed } from '@validations'
import useToastHandler from '@/services/toastHandler'
import { objectProp } from '@/helpers/props'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    Spinner,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    userData: objectProp(),
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      typeCurrentPassword: 'password',
      typeNewPassword: 'password',
      typeConfirmPassword: 'password',
      required,
      min,
      confirmed,
    }
  },
  computed: {
    ...mapState('account/changePassword', ['loading', 'error']),

    passwordToggleIconOld() {
      return this.typeCurrentPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.typeNewPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.typeConfirmPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      changePasswordAsync: 'account/changePassword/changePasswordAsync',
    }),
    togglePasswordOld() {
      this.typeCurrentPassword = this.typeCurrentPassword === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.typeNewPassword = this.typeNewPassword === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirm() {
      this.typeConfirmPassword = this.typeConfirmPassword === 'password' ? 'text' : 'password'
    },
    async handleChangePassword() {
      const valid = await this.$refs.simpleRules.validate()
      if (!valid) return

      const result = await this.changePasswordAsync({
        currentPassword: this.currentPassword,
        password: this.newPassword,
      })

      if (!result) return
      this.showToastSuccess('Successs', 'Password changed successfully')
      this.resetForm()
      this.$router.push({ name: 'auth-login' })
    },
    resetForm() {
      this.newPassword = ''
      this.confirmPassword = ''
      this.$refs.simpleRules.reset()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    return {
      showToastError,
      showToastSuccess,
    }
  },
}
</script>
