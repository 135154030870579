<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="infoForm"
      v-slot="{ changed }"
    >
      <b-form>
        <b-row>
          <!-- bio -->
          <b-col cols="12">
            <b-form-group>
              <div class="d-flex">
                <label for="bio-area">Bio</label>
                <label class="ml-auto text-muted">
                  {{ (localOptions.bio && localOptions.bio.length) || 0 }} / 200 Characters
                </label>
              </div>

              <validation-provider
                name="bio"
                vid="bio"
              >
                <b-form-textarea
                  id="bio-area"
                  v-model="localOptions.bio"
                  rows="4"
                  maxlength="200"
                  placeholder="Your bio data here..."
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- first name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="First Name"
              label-for="account-firstname"
            >
              <validation-provider
                #default="{ errors }"
                name="firstName"
                vid="firstName"
                rules="required"
              >
                <b-form-input
                  v-model="localOptions.firstName"
                  placeholder="First name"
                  name="firstName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- middle name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Middle Name"
              label-for="account-middlename"
            >
              <validation-provider
                name="middleName"
                vid="middleName"
              >
                <b-form-input
                  v-model="localOptions.middleName"
                  placeholder="Middle name"
                  name="middleName"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- last name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Last Name"
              label-for="account-lastname"
            >
              <validation-provider
                #default="{ errors }"
                name="lastName"
                vid="lastName"
                rules="required"
              >
                <b-form-input
                  v-model="localOptions.lastName"
                  placeholder="Last name"
                  name="lastName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- birth date -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label-for="example-datepicker"
              label="Birth date"
            >
              <validation-provider
                name="dob"
                vid="dob"
              >
                <flat-pickr
                  v-model="localOptions.dob"
                  class="form-control"
                  name="dob"
                  placeholder="Birth date"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- phone -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label-for="phone"
              label="Phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phoneNumber"
                vid="phoneNumber"
                rules="required"
              >
                <cleave
                  v-model="localOptions.phoneNumber"
                  class="form-control"
                  :raw="false"
                  :options="clevePhone"
                  placeholder="Phone number"
                  :disabled="!isRoleSuperAdmin()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- phone -->

          <!-- last name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <div class="d-flex">
                <label for="motto">Motto</label>
                <label class="ml-auto text-muted">
                  {{ (localOptions.motto && localOptions.motto.length) || 0 }} / 50 Characters
                </label>
              </div>

              <validation-provider
                name="motto"
                vid="motto"
              >
                <b-form-input
                  v-model="localOptions.motto"
                  name="motto"
                  placeholder="Your motto data here..."
                  maxlength="50"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Title -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <validation-provider
                name="title"
                vid="title"
              >
                <b-form-radio-group
                  id="title"
                  v-model="localOptions.title"
                  :options="titleOptions"
                  value="Mr"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
              label-class="mb-1"
            >
              <validation-provider
                name="gender"
                vid="gender"
              >
                <b-form-radio-group
                  id="gender"
                  v-model="localOptions.gender"
                  :options="genderOptions"
                  value="M"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Address
          </h4>

          <div
            class="ml-auto user-select-none cursor-pointer text-info"
            @click="toggleManual"
          >
            <span>{{ isManual ? 'Lookup address ' : 'Enter address manually' }}</span>
          </div>
        </div>

        <b-row class="mt-1">
          <!-- Autocomplete -->
          <!-- Address  -->
          <template v-if="!isManual">
            <b-col
              cols="12"
              xl="6"
            >
              <validation-provider
                name="residentialAddress"
                vid="residentialAddress"
              >
                <AutoCompleteInput
                  v-model="addressInfo.residentialAddress"
                  tab-index="11"
                  :placeholder="localOptions.residentialAddress"
                  @input="submitAddress"
                />
              </validation-provider>
            </b-col>
          </template>

          <!-- Manual -->
          <!-- Address -->
          <template v-else-if="isManual">
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Address"
                label-for="address"
              >
                <validation-provider
                  name="residentialAddress"
                  vid="residentialAddress"
                >
                  <b-form-input
                    v-model="addressInfo.residentialAddress"
                    :placeholder="localOptions.residentialAddress"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- City -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="City/Suburb"
                label-for="city-suburb"
              >
                <validation-provider
                  name="suburbAddress"
                  vid="suburbAddress"
                >
                  <b-form-input
                    v-model="addressInfo.suburbAddress"
                    :placeholder="localOptions.suburbAddress"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  State -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="State"
                label-for="state"
              >
                <validation-provider
                  name="stateAddress"
                  vid="stateAddress"
                >
                  <v-select
                    v-model="addressInfo.stateAddress"
                    :options="stateAddress"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="state"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Postcode -->
            <b-col
              ccols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Postcode"
                label-for="postcode"
              >
                <validation-provider
                  name="postcode"
                  vid="postcode"
                >
                  <b-form-input
                    id="postcode"
                    v-model="addressInfo.postcode"
                    type="number"
                    :placeholder="localOptions.postcode"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-1 mr-1"
              :disabled="loading || !changed"
              @click.prevent="handleSubmitForm"
            >
              <Spinner v-if="loading" />
              <template v-else>
                Save changes
              </template>
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              :disabled="loading"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import Spinner from '@core/components/Spinner'
import AutoCompleteInput from '@core/components/AutoCompleteInput'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { arrayProp, booleanProp, objectProp } from '@/helpers/props'
import useToastHandler from '@/services/toastHandler'
import { isRoleSuperAdmin } from '@/helpers/permission'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormRadioGroup,
    vSelect,
    flatPickr,
    Cleave,
    Spinner,
    AutoCompleteInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    accountData: objectProp(),
    stateAddress: arrayProp(),
    loading: booleanProp(),
  },
  data() {
    return {
      required,
      localOptions: JSON.parse(JSON.stringify(this.accountData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'AUS',
      },
      isManual: false,
      addressInfo: {
        residentialAddress: '',
        suburbAddress: '',
        stateAddress: this.accountData.stateAddress,
        postcode: '',
      },
    }
  },
  computed: {
    ...mapState('account/update', ['error']),
  },
  methods: {
    ...mapActions({
      updateAccountAsync: 'account/update/updateAccountAsync',
    }),

    async handleSubmitForm() {
      const valid = await this.$refs.infoForm.validate()
      if (!valid || !this.$refs.infoForm.flags.changed) return

      const result = await this.updateAccountAsync({
        id: this.localOptions.userId,
        body: {
          ...(this.isRoleSuperAdmin() && {
            ...this.handleCheckKey('phoneNumber'),
          }),
          ...this.handleCheckKey('bio'),
          ...this.handleCheckKey('firstName'),
          ...this.handleCheckKey('middleName'),
          ...this.handleCheckKey('lastName'),
          ...this.handleCheckKey('dob'),
          ...this.handleCheckKey('motto'),
          ...this.handleCheckKey('title'),
          ...this.handleCheckKey('gender'),
          ...this.handleCheckKeyAddres('residentialAddress'),
          ...this.handleCheckKeyAddres('suburbAddress'),
          ...this.handleCheckKeyAddres('stateAddress'),
          ...this.handleCheckKeyAddres('postcode'),
        },
      })
      if (!result) return
      this.showToastSuccess('Update Success', 'Account updated successfully')
      this.$refs.infoForm.reset()
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.accountData))
      this.addressInfo.residentialAddress = ''
      this.addressInfo.suburbAddress = ''
      this.addressInfo.postcode = ''
      this.addressInfo.stateAddress = this.accountData.stateAddress
    },
    submitAddress(val) {
      this.addressInfo.residentialAddress = val.address
      this.addressInfo.suburbAddress = val.city
      this.addressInfo.postcode = val.postCode
      this.addressInfo.stateAddress = val.state
    },
    toggleManual() {
      this.isManual = !this.isManual
    },
    handleCheckKey(key) {
      if (this.localOptions[key] !== this.accountData[key]) return { [key]: this.localOptions[key] }
    },
    handleCheckKeyAddres(key) {
      if (!this.addressInfo[key] || this.addressInfo[key] === this.accountData[key]) return
      return { [key]: this.addressInfo[key] }
    },
  },
  setup() {
    const genderOptions = [
      { text: 'Male', value: 'M' },
      { text: 'Female', value: 'F' },
    ]

    const titleOptions = [
      { text: 'Mr', value: 'Mr' },
      { text: 'Ms', value: 'Ms' },
    ]

    const { showToastSuccess } = useToastHandler()

    return {
      isRoleSuperAdmin,
      genderOptions,
      titleOptions,
      showToastSuccess,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
