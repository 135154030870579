<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <AccountSettingGeneral
        v-if="user"
        :general-data="user"
        :loading="accountUpdate.loading"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <AccountSettingPassword />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template>

      <AccountSettingInformation
        v-if="user"
        :account-data="user"
        :state-address="stateAddress"
        :loading="accountUpdate.loading"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
  },
  computed: {
    ...mapState({
      user: s => s.auth.user,
      stateAddress: s => s.user.newUserParams.state,
      accountUpdate: s => s.account.update,
    }),
  },
  async created() {
    await this.getNewUserParamsAsync()
  },
  methods: {
    ...mapActions({
      getNewUserParamsAsync: 'user/newUserParams/getNewUserParamsAsync',
    }),
  },
}
</script>
