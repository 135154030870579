export function csvToArray(str, delimiter = ',') {
  const headers = str.slice(0, str.indexOf('\r\n')).split(delimiter)
  const rows = str.slice(str.indexOf('\n') + 1).split('\r\n')
  const arr = rows.map(row => {
    const values = row.split(delimiter)
    const el = headers.reduce((object, header, index) => {
      object[header] = values[index]
      return object
    }, {})
    return el
  })

  return arr
}

export const useInputImageRenderer = (inputEl, callback) => {
  const file = inputEl.files[0]
  const reader = new FileReader()

  reader.addEventListener(
    'load',
    () => {
      callback(reader.result)
    },
    false,
  )

  if (file) {
    reader.readAsDataURL(file)
    inputEl.value = null
  }
}

export const useInputCSV = (inputEl, callback) => {
  const file = inputEl.files[0]
  const reader = new FileReader()

  reader.addEventListener(
    'load',
    e => {
      const text = e.target.result
      const result = csvToArray(text)
      callback(result)
    },
    false,
  )

  if (file) {
    reader.readAsText(file)
    inputEl.value = null
  }
}

export const _ = null
