<template>
  <b-card>
    <validation-observer
      ref="generalForm"
      #default="{ changed }"
    >
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <validation-provider
            name="profileUrl"
            vid="profileUrl"
          >
            <b-avatar
              ref="previewEl"
              v-model="optionsLocal.profileUrl"
              :src="optionsLocal.profileUrl"
              :text="avatarText(generalData.fullName)"
              size="90px"
              rounded
            />
          </validation-provider>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Upload
          </b-button>

          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="removeImageRenderer"
          >
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="User Name"
              label-for="userName"
            >
              <validation-provider
                #default="{ errors }"
                name="userName"
                vid="username"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.userName"
                  name="userName"
                  placeholder="User name"
                  :disabled="!isRoleSuperAdmin()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                  :disabled="!isRoleSuperAdmin()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              :disabled="loading || !changed"
              @click.prevent="handleSubmitForm"
            >
              <Spinner v-if="loading" />
              <template v-else>
                Save changes
              </template>
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :disabled="loading"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { mapActions } from 'vuex'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Spinner from '@core/components/Spinner'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { booleanProp, objectProp } from '@/helpers/props'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'

export default {
  components: {
    BAvatar,
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    Spinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: objectProp(),
    loading: booleanProp(),
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      fileImage: null,
      disabledForm: false,
      required,
      email,
      disabled: false,
    }
  },
  methods: {
    ...mapActions({
      updateAccountAsync: 'account/update/updateAccountAsync',
    }),

    async handleSubmitForm() {
      const valid = await this.$refs.generalForm.validate()
      if (!valid || !this.$refs.generalForm.flags.changed) return

      const formData = new FormData()
      formData.append('image', this.fileImage)

      const body = {
        ...(this.isRoleSuperAdmin() && {
          ...this.handleCheckKey('userName'),
          ...this.handleCheckKey('email'),
        }),
        ...(!this.optionsLocal.profileUrl && {
          profileUrl: this.optionsLocal.profileUrl,
        }),
      }

      const result = await this.updateAccountAsync({
        id: this.generalData.userId,
        body,
        avatar: formData,
      })

      if (!result) return
      this.showToastSuccess('Update Success', 'Account updated successfully')
      this.fileImage = null
      this.$refs.generalForm.reset()
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    // Handle Image Renderer
    inputImageRenderer(evt) {
      this.fileImage = evt.target.files[0]
      useInputImageRenderer(evt.target, base64 => {
        this.optionsLocal.profileUrl = base64
        this.$refs.generalForm.validate()
      })
    },
    removeImageRenderer() {
      this.optionsLocal.profileUrl = null
      this.$refs.generalForm.validate()
    },
    handleCheckKey(key) {
      return this.generalData[key] !== this.optionsLocal[key] && { [key]: this.optionsLocal[key] }
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    return {
      avatarText,
      isRoleSuperAdmin,
      showToastError,
      showToastSuccess,
    }
  },
}
</script>
